/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import styled from 'styled-components';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import {
  SectionTitle,
} from '../Global';
import MediaBreakDown from '../../styles/MediaBreakDown';


const BlogSectionWrapper = styled.div`
  margin: 50px 0 0 0;

  ${SectionTitle} {
    text-align: center;
  }

  ${MediaBreakDown.lg`
  `}
`;
BlogSectionWrapper.displayName = 'BlogSectionWrapper';


/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/

export default BlogSectionWrapper;
