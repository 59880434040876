/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import _get from 'lodash/get';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/Container';
import Image from '../components/Image';
import {
  BodyTextBig,
  CTASection,
  GlobalHero,
  SectionTitle,
} from '../components/Global';
import MediaBreakDown from '../styles/MediaBreakDown';
import CallToAction from '../components/CallToAction';
import BlogSection from '../components/Blog/BlogSection';
import SignUpModal from '../components/SignUpModal';


const Hero = styled(GlobalHero)`
  align-items: center;
  padding: 30px 0 50px;

  .hero-text {
    width: 550px;
  }
  .hero-img {
    width: 480px;
  }

  ${MediaBreakDown.lg`
    .hero-text {
      a, button {
        display: none;
      }
    }
  `}
`;

const SectionTitleFooter = styled(SectionTitle)`
  max-width: initial !important;
  margin: 0 0 40px 0 !important;
`;


const NotFoundPage = ({ location }) => {
  React.useEffect(() => {
    trackCustomEvent({
      category: '404 Error',
      action: document.URL,
      label: document.referrer,
    });
  }, []);
  const data = useStaticQuery(
    graphql`
    query {
      allContentfulBlogPost(
        sort: { fields: [publishDate], order: DESC }
        limit: 6
      ) {
        edges {
          node {
            id
            title
            slug
            featuredImage {
              description
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    `
  );

  const blogArticles = _get(data, 'allContentfulBlogPost.edges', [])
    .map(itemEdge => (_get(itemEdge, 'node')));

  return (
    <Layout location={location}>
      <SEO title="404: Not Found" />
      <Container>
        <Hero>
          <div className="hero-text">
            <SectionTitle>
              The page that you are looking for is no longer here
            </SectionTitle>
            <BodyTextBig mt="30px" mb="45px">
              If you think this is an issue contact us at
              {' '}
              <a href="mailto:support@workast.com">support@workast.com</a>
            </BodyTextBig>
            <CallToAction
              text="Back to the homepage"
              link="/"
            />
          </div>
          <div className="hero-img">
            <Image name="404_big.png" alt="404" />
            <CallToAction
              text="Back to the homepage"
              link="/"
            />
          </div>
        </Hero>
      </Container>
  
      {/* BLOG ARTICLES */}
      {blogArticles.length > 0 && (
        <Container>
          <BlogSection
            title="Fresh from our blog"
            items={blogArticles}
          />
        </Container>
      )}

      {/* CTA */}
      <CTASection>
        <Container>
          <SectionTitleFooter>
            Make team work simple with Workast
            <span role="img" aria-label="All good">✌🏻</span>
          </SectionTitleFooter>
          <SignUpModal buttonText="Try it for free" />
        </Container>
      </CTASection>
    </Layout>
  );
};

NotFoundPage.propTypes = {
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
};

export default NotFoundPage;
