/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import PostCard from './PostCard';
import { Grid, GridItem } from '../Grid';
// Styles
import BlogSectionWrapper from './BlogSection.style';
import {
  SectionTitle,
} from '../Global';
import SliderWrap, {
  SliderCard,
} from '../Slider/index.style';
import constants from '../../constants';


const defaultSettings = {
  autoplay: true,
  autoplaySpeed: 5000,
  centerMode: true,
  centerPadding: '40px',
  dots: true,
  infinite: true,
  pauseOnHover: true,
  slidesToShow: 3,
  speed: 500,
  swipeToSlide: true,
  responsive: [{
    breakpoint: 1200,
    settings: {
      slidesToShow: 1,
      centerPadding: '15%',
    }
  }, {
    breakpoint: 440,
    settings: {
      slidesToShow: 1,
      centerPadding: '5%',
    }
  }]
};


const BlogSection = (props) => {
  const {
    title,
    items,
    useCarousel,
  } = props;
  const refSlider = React.useRef();


  const sliderComponent = (
    <SliderWrap>
      <Slider
        {...defaultSettings}
        ref={refSlider}
      >
        {items.map(item => (
          <SliderCard
            key={item.id}
            size={constants.SIZES.MEDIUM}
          >
            <PostCard
              featuredImage={item.featuredImage}
              title={item.title}
              slug={item.slug}
            />
          </SliderCard>
        ))}
      </Slider>
    </SliderWrap>
  );
  const gridComponent = (
    <Grid gridGap="4rem" minCol="290px">
      {items.map(item => (
        <GridItem key={item.id}>
          <PostCard
            featuredImage={item.featuredImage}
            title={item.title}
            slug={item.slug}
          />
        </GridItem>
      ))}
    </Grid>
  );
  const content = useCarousel
    ? (
      <div className="slider-wrapper">
        {sliderComponent}
      </div>
    )
    : (
      <div className="grid-wrapper">
        {gridComponent}
      </div>
    );


  return (
    <BlogSectionWrapper>
      <SectionTitle mb="20px">{title}</SectionTitle>
      {content}
    </BlogSectionWrapper>
  );
};


BlogSection.defaultProps = {
  title: 'From our blog',
  items: [],
  useCarousel: false,
};
BlogSection.propTypes = {
  // Optional props:
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      featuredImage: PropTypes.shape({}),
      title: PropTypes.string,
      slug: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  useCarousel: PropTypes.bool,
};


/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default BlogSection;
